<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 reception-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "utilities",
              "qr-code-templates",
              "qr-code-templates"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('utilities', 'qr-code-templates', 'search')
            "
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>

    <ul
      class="clebex-item-section pill"
      v-if="listOfTemplates && listOfTemplates.length"
    >
      <li
        class="clebex-item-section-item"
        :class="{ 'full-right-underline': false }"
        v-for="template in listOfTemplates"
        :key="template.id"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-content-wrapper">
            <div class="checkbox tiny block alt">
              <input
                type="radio"
                :id="`template${template.id}`"
                name="template"
                :value="template.id"
                :checked="
                  selectedTemplate && selectedTemplate.id === template.id
                "
                @change="setTemplate(template)"
              />
              <label :for="`template${template.id}`">
                <icon icon="#cx-app1-checkmark" />
                <dl class="clebex-item-dl no-left-padding">
                  <dt class="clebex-item-dt"></dt>
                  <dd class="clebex-item-dd">
                    <div class="link">
                      {{ template.name }}
                    </div>
                  </dd>
                </dl>
              </label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </mobile-screen>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "Qr Code Templates",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      searchQuery: "",
      tempaltes: null
    };
  },
  created() {
    this.getTemplates();
  },
  computed: {
    ...mapState("qrCode", ["templates", "selectedTemplate"]),
    listOfTemplates() {
      const query = this.searchQuery;
      if (this.templates && this.templates.length) {
        if (query && query.length > 1) {
          return this.templates.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.templates;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("qrCode", ["setSelectedTemplate", "getTemplates"]),
    setTemplate(template) {
      this.setSelectedTemplate(template);
      this.$router.push({ name: this.backLinkName });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
